<template>
  <div>
    <el-button type="primary" @click="handleAddGradeBtn">添加年级</el-button>
    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          label="学段名称"
          prop="schoolSectionDicName"
        ></el-table-column>
        <el-table-column
          label="年级名称"
          prop="sysSchoolClassGradeName"
        ></el-table-column>
        <el-table-column
          label="年级码"
          prop="sysSchoolClassGradeCode"
        ></el-table-column>
        <el-table-column
          label="年级描述"
          prop="sysSchoolClassGradeDescription"
        ></el-table-column>
        <el-table-column
          label="班级信息"
          prop="sysOrgSchoolClassList"
        ></el-table-column>
        <!-- <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.enabled == 1 ? "启用" : "禁用" }}
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              style="cursor: pointer; color: #00f; margin-right: 10px"
              @click="handleEditSessionDic(scope.row)"
              >编辑</span
            >
            <span
              style="cursor: pointer; color: #f40"
              @click="
                handleSessionEnabledChange(
                  scope.row.sysSchoolClassGradeId,
                  '删除'
                )
              "
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTableDataMsgPage"
        @current-change="handleCurrentChangeTableDataMsgPage"
        :current-page="tableDataMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataMsgPage.pageTotal"
      ></el-pagination>
    </el-row>

    <el-dialog
      :title="addOrEdit === 1 ? '新增年级' : '编辑年级'"
      :visible.sync="gradeDialogVisible"
      width="600px"
    >
      <div style="width: 500px; margin: 0 auto">
        <el-form
          :model="dicValueDialogForm"
          ref="dicValueDialogForm"
          class="dicValueDialogForm"
          :rules="rules"
        >
          <el-form-item prop="schoolSectionDicId" label="学段:">
            <el-select
              v-model="dicValueDialogForm.schoolSectionDicId"
              style="width: 400px; margin-left: 27px"
            >
              <el-option
                v-for="item in periodList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="sysSchoolClassGradeName" label="年级名称:">
            <el-input
              v-model="dicValueDialogForm.sysSchoolClassGradeName"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysSchoolClassGradeCode" label="年级码:">
            <el-input
              v-model="dicValueDialogForm.sysSchoolClassGradeCode"
              style="width: 400px; margin-left: 14px"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysSchoolClassGradeDescription" label="年级描述:">
            <el-input
              v-model="dicValueDialogForm.sysSchoolClassGradeDescription"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            prop="sysSchoolClassGradeNum"
            label="年级:"
            style="margin-left: 11px"
          >
            <el-select
              v-model="dicValueDialogForm.sysSchoolClassGradeNum"
              style="width: 400px; margin-left: 29px"
            >
          <el-option label="一年级" :value="1"></el-option>
          <el-option label="二年级" :value="2"></el-option>
          <el-option label="三年级" :value="3"></el-option>
          <el-option label="四年级" :value="4"></el-option>
          <el-option label="一年级" :value="5"></el-option>
          <el-option label="一年级" :value="6"></el-option>
        </el-select>
          </el-form-item> -->
          <el-form-item>
            <div style="text-align: center">
              <el-button
                v-throttle
                @click="
                  () => {
                    addOrEditDicValueDialogVisible = false;
                  }
                "
                >取消</el-button
              >
              <el-button
                v-throttle
                type="primary"
                @click="handleConfirmAddOrEditDicFormBtnClick"
                >确认</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addGradeDicApi,
  delGradeDicApi,
  getGradeDicDetailApi,
  getTermSessionGradeClassDicInfoApi,
  getGradeDicListApi,
  updateGradeDicApi,
  getDicFormInfoApi,
} from "@/api/standard/dictionaryForm";
export default {
  data() {
    return {
      tableCondition: {
        enabled: "",
        schoolSectionDiGroupName: "",
        sysSchoolClassGradeName: "",
      },
      tableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
      addOrEdit: 1, // 1 新增 0 编辑
      gradeDialogVisible: false,
      dicValueDialogForm: {
        schoolSectionDicId: "",
        sysSchoolClassGradeCode: "",
        sysSchoolClassGradeDescription: "",
        sysSchoolClassGradeName: "",
        // sysSchoolClassGradeNum: "",
      },
      rules: {
        schoolSectionDicId: [
          { required: true, message: "请选择学段", trigger: "change" },
        ],
        sysSchoolClassGradeCode: [
          { required: true, message: "请输入学年码", trigger: "blur" },
        ],
        sysSchoolClassGradeDescription: [
          { required: true, message: "请输入学年描述", trigger: "blur" },
        ],
        sysSchoolClassGradeName: [
          { required: true, message: "请输入学年名称", trigger: "blur" },
        ],
      },
      periodList: [],
    };
  },
  created() {
    this.fetchGradeDic();
    this.fetchPeriodInfo();
  },
  methods: {
    // 获取年级字典表
    fetchGradeDic() {
      getGradeDicListApi(
        this.tableDataMsgPage.pageIndex,
        this.tableDataMsgPage.pageSize,
        this.tableCondition
      ).then((res) => {
        if (res.success) {
          this.tableData = res.data;
          this.tableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取科目以及学段信息
    fetchPeriodInfo() {
      getDicFormInfoApi(5).then((res) => {
        if (res.success) {
          this.periodList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加年级
    handleAddGradeBtn() {
      this.dicValueDialogForm = {
        schoolSectionDicId: "",
        sysSchoolClassGradeCode: Math.random().toString(16).substring(2, 8),
        sysSchoolClassGradeDescription: "",
        sysSchoolClassGradeName: "",
        // sysSchoolClassGradeNum: "",
      };
      this.addOrEdit = 1;
      this.gradeDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.dicValueDialogForm.clearValidate()
      })
    },
    // 编辑
    handleEditSessionDic(row) {
      getGradeDicDetailApi(row.sysSchoolClassGradeId).then((res) => {
        if (res.success) {
          this.dicValueDialogForm = {
            schoolSectionDicId: res.data.schoolSectionDicId,
            sysSchoolClassGradeCode: res.data.sysSchoolClassGradeCode,
            sysSchoolClassGradeDescription:
              res.data.sysSchoolClassGradeDescription,
            sysSchoolClassGradeName: res.data.sysSchoolClassGradeName,
            // sysSchoolClassGradeNum: "",
          };
          this.addOrEdit = 0;
          this.sysSchoolClassGradeId = row.sysSchoolClassGradeId;
          this.gradeDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },

    // 确认按钮
    handleConfirmAddOrEditDicFormBtnClick() {
      this.$refs.dicValueDialogForm.validate((valid) => {
        if (valid) {
          if (this.addOrEdit == 1) {
            let addParams = {
              ...this.dicValueDialogForm,
              sysSchoolClassGradeNum: this.handleChineseNumTranform(
                this.dicValueDialogForm.sysSchoolClassGradeName
              ),
              // enabled: 1,
            };
            addGradeDicApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success("添加成功");
                this.fetchGradeDic();
                this.gradeDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let editParams = {
              ...this.dicValueDialogForm,
              sysSchoolClassGradeNum: this.handleChineseNumTranform(
                this.dicValueDialogForm.sysSchoolClassGradeName
              ),
              sysSchoolClassGradeId: this.sysSchoolClassGradeId,
              // enabled: 1,
            };
            updateGradeDicApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功");
                this.fetchGradeDic();
                this.gradeDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },

    // 改变节次字典表状态
    handleSessionEnabledChange(id, keyWord) {
      this.$confirm(`确定${keyWord}该年级吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (keyWord === "删除") {
            // 进行删除操作
            delGradeDicApi([id]).then((res) => {
              if (res.success) {
                this.fetchGradeDic();
                this.$message({
                  type: "success",
                  message: `${keyWord}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let params = {
              sysSchoolClassGradeId: id,
              enabled: keyWord === "启用" ? 1 : 2,
            };
            // 进行编辑操作
            updateGradeDicApi(params).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: `${keyWord}成功!`,
                });
                this.fetchGradeDic();
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${keyWord}`,
          });
        });
    },
    // 分页
    handleCurrentChangeTableDataMsgPage(val) {
      this.tableDataMsgPage.pageIndex = val;
      this.fetchGradeDic();
    },
    handleSizeChangeTableDataMsgPage(val) {
      this.tableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = val;
      this.fetchGradeDic();
    },
    // 调用该方法判断年级名称中的中文数字
    handleChineseNumTranform(str) {
      if (str.includes("一")) return 1;
      if (str.includes("二")) return 2;
      if (str.includes("三")) return 3;
      if (str.includes("四")) return 4;
      if (str.includes("五")) return 5;
      if (str.includes("六")) return 6;
      if (str.includes("七")) return 7;
      if (str.includes("八")) return 8;
      if (str.includes("九")) return 9;
      return 1;
    },
  },
};
</script>

<style></style>
