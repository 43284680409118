<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="基础字典表">
        <!-- 字典表 -->
        <div class="dic-form-container">
          <div class="left-container">
            <div class="title">字典表</div>
            <div class="left-content">
              <el-input placeholder="请输入关键词" v-model="searchInputContent">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <div class="left-item-container">
                <div
                  class="left-item"
                  v-for="item in dicFormData"
                  :key="item.code"
                  :class="{ selected: item.isSelected === 1 }"
                  @click="handleLeftItemClick(item)"
                >
                  <div style="margin-left: 50px">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-container">
            <div class="title">字典值</div>
            <div style="margin-top: 20px; text-align: left">
              <el-button
                type="primary"
                style="width: 100px"
                v-throttle
                icon="el-icon-circle-plus-outline"
                @click="handleAddValueBtnClick"
                >添加值</el-button
              >
            </div>
            <el-row style="margin-top: 20px">
              <el-table
                :header-cell-style="{
                  'text-align': 'center',
                  backgroundColor: '#dfe6ec',
                }"
                :cell-style="{ 'text-align': 'center' }"
                :data="dicFormValueTableList"
                border
              >
                <el-table-column
                  label="序号"
                  type="index"
                  width="80"
                ></el-table-column>
                <el-table-column
                  prop="sysDicCode"
                  label="code"
                ></el-table-column>
                <el-table-column prop="sysDicName" label="值"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scoped">
                    <span
                      style="
                        padding-right: 5px;
                        border-right: 1px solid #ccc;
                        color: red;
                        cursor: pointer;
                      "
                      @click="handleDeleteDicFormValueClick(scoped.row)"
                    >
                      删除
                    </span>
                    <span
                      style="
                        margin-left: 10px;
                        color: rgb(41, 82, 216);
                        cursor: pointer;
                      "
                      @click="handleEditDicFormValueBtnClick(scoped.row)"
                    >
                      编辑
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <!-- 分页组件 -->
            <el-row style="margin-top: 30px">
              <el-pagination
                @size-change="handleSizeChangeDicTableDataMsgPage"
                @current-change="handleCurrentChangeDicTableDataMsgPage"
                :current-page="dicTableDataMsgPage.pageIndex"
                :page-sizes="[10, 20, 50, 100, 150, 200]"
                :page-size="dicTableDataMsgPage.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="dicTableDataMsgPage.pageTotal"
              ></el-pagination>
            </el-row>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="节次字典表">
        <!-- 节次字典表 -->
        <div>
          <el-row>
            <el-button type="primary" @click="handleAddSessionDic"
              >添加节次字典表</el-button
            ></el-row
          >
          <el-row style="margin-top: 20px">
            <el-form :model="sessionCondition" inline>
              <el-form-item
                label="节次名称："
                prop="sysSchoolClassSessionName"
                class="formItemBoxStyle"
              >
                <el-input
                  v-model="sessionCondition.sysSchoolClassSessionName"
                  placeholder="请输入"
                  @blur="handleQuerySessionTable"
                />
              </el-form-item>
              <!-- <el-form-item
                label="节次信息："
                prop="sysSchoolClassSessionNum"
                class="formItemBoxStyle"
              >
                <el-select
                  v-model="sessionCondition.sysSchoolClassSessionNum"
                  @change="handleQuerySessionTable"
                >
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="第一节" :value="1"></el-option>
                  <el-option label="第二节" :value="2"></el-option>
                  <el-option label="第三节" :value="3"></el-option>
                  <el-option label="第四节" :value="4"></el-option>
                  <el-option label="第五节" :value="5"></el-option>
                  <el-option label="第六节" :value="6"></el-option>
                  <el-option label="第七节" :value="7"></el-option>
                  <el-option label="第八节" :value="8"></el-option>
                </el-select>
              </el-form-item> -->
              <!-- <el-form-item
                label="状态："
                prop="enabled"
                class="formItemBoxStyle"
              >
                <el-select
                  v-model="sessionCondition.enabled"
                  @change="handleQuerySessionTable"
                >
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="启用" :value="1"></el-option>
                  <el-option label="禁用" :value="2"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item>
                <el-button @click="handleResetSessionTable">重置</el-button>
                <el-button @click="handleQuerySessionTable" type="primary"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-table
              :data="sessionData"
              :header-cell-style="{
                'text-align': 'center',
                backgroundColor: '#dfe6ec',
              }"
              :cell-style="{ 'text-align': 'center' }"
            >
              <el-table-column
                label="节次名称"
                prop="sysSchoolClassSessionName"
              ></el-table-column>
              <el-table-column
                label="节次码"
                prop="sysSchoolClassSessionCode"
              ></el-table-column>
              <el-table-column
                label="开始时间"
                prop="sysSchoolClassSessionStartTime"
                :formatter="customFormatter"
              ></el-table-column>
              <el-table-column
                label="结束时间"
                prop="sysSchoolClassSessionEndTime"
                :formatter="customFormatter"
              ></el-table-column>
              <el-table-column
                label="节次描述"
                prop="sysSchoolClassSessionDescription"
              ></el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  {{ scope.row.enabled == 1 ? "启用" : "禁用" }}
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div v-if="scope.row.enabled == 1">
                    <span
                      style="cursor: pointer; color: #f40"
                      @click="
                        handleSessionEnabledChange(
                          scope.row.sysSchoolClassSessionId,
                          '停用'
                        )
                      "
                      >停用</span
                    >
                  </div>
                  <div v-else>
                    <span
                      style="cursor: pointer; color: #00f"
                      @click="handleEditSessionDic(scope.row)"
                      >编辑</span
                    >
                    <span
                      style="cursor: pointer; margin: 0 10px; color: green"
                      @click="
                        handleSessionEnabledChange(
                          scope.row.sysSchoolClassSessionId,
                          '启用'
                        )
                      "
                      >启用</span
                    >
                    <span
                      style="cursor: pointer; color: #f40"
                      @click="
                        handleSessionEnabledChange(
                          scope.row.sysSchoolClassSessionId,
                          '删除'
                        )
                      "
                      >删除</span
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- 分页组件 -->
          <el-row style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChangeSessionDicTableDataMsgPage"
              @current-change="handleCurrentChangeSessionDicTableDataMsgPage"
              :current-page="sessionDicTableDataMsgPage.pageIndex"
              :page-sizes="[10, 20, 50, 100, 150, 200]"
              :page-size="sessionDicTableDataMsgPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="sessionDicTableDataMsgPage.pageTotal"
            ></el-pagination>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="年级字典表">
        <gradeDicCom></gradeDicCom>
      </el-tab-pane>
      <el-tab-pane label="学期字典表">
        <termDicCom></termDicCom>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加或编辑弹框 -->
    <el-dialog
      :title="addOrEditValue === 1 ? '添加字典值' : '编辑字典值'"
      :visible.sync="addOrEditDicValueDialogVisible"
      width="600px"
    >
      <div style="width: 500px; margin: 0 auto">
        <el-form
          :model="dicValueDialogForm"
          ref="dicValueDialogForm"
          class="dicValueDialogForm"
          :rules="rules"
        >
          <el-form-item prop="sysDicGroupName">
            <span class="redStar">字典表名称：</span>
            <el-input
              v-model="dicValueDialogForm.sysDicGroupName"
              style="width: 400px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysDicGroupCode">
            <span class="redStar">字典表编码：</span>
            <el-input
              v-model="dicValueDialogForm.sysDicGroupCode"
              style="width: 400px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysDicName">
            <span class="redStar" style="margin-left: 55px">值：</span>
            <el-input
              v-model="dicValueDialogForm.sysDicName"
              style="width: 400px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysDicCode">
            <span class="redStar" style="margin-left: 36px">code：</span>
            <el-input
              v-model="dicValueDialogForm.sysDicCode"
              style="width: 400px"
              :disabled="addOrEditValue !== 1"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: center">
              <el-button
                v-throttle
                @click="
                  () => {
                    addOrEditDicValueDialogVisible = false;
                  }
                "
                >取消</el-button
              >
              <el-button
                v-throttle
                type="primary"
                @click="handleConfirmAddOrEditDicFormBtnClick"
                >确认</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 添加或编辑节次字典表弹框 -->
    <el-dialog
      :visible.sync="sessionDialogVisible"
      :title="addOrEditSession ? '添加节次' : '编辑节次'"
    >
      <div style="width: 700px; margin: 10px auto">
        <el-form
          :model="sessionDialogForm"
          ref="sessionDialogForm"
          :rules="rules"
        >
          <el-form-item label="节次名称：" prop="sysSchoolClassSessionName">
            <el-input
              style="width: 600px"
              v-model="sessionDialogForm.sysSchoolClassSessionName"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              maxlength="20"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="节次码：" prop="sysSchoolClassSessionCode">
            <el-input
              style="width: 600px; margin-left: 14px"
              v-model="sessionDialogForm.sysSchoolClassSessionCode"
              maxlength="20"
              placeholder="请输入"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            label="节次信息："
            prop="sysSchoolClassSessionNum"
            style="margin-left: 10px"
          >
            <el-select
              v-model="sessionDialogForm.sysSchoolClassSessionNum"
              placeholder="请选择"
              style="width: 600px"
            >
              <el-option label="第一节" :value="1"></el-option>
              <el-option label="第二节" :value="2"></el-option>
              <el-option label="第三节" :value="3"></el-option>
              <el-option label="第四节" :value="4"></el-option>
              <el-option label="第五节" :value="5"></el-option>
              <el-option label="第六节" :value="6"></el-option>
              <el-option label="第七节" :value="7"></el-option>
              <el-option label="第八节" :value="8"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item
            label="开始时间："
            prop="sysSchoolClassSessionStartTime"
          >
            <el-time-select
              v-model="sessionDialogForm.sysSchoolClassSessionStartTime"
              style="width: 600px"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '18:30',
              }"
              placeholder="选择时间"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item label="结束时间：" prop="sysSchoolClassSessionEndTime">
            <el-time-select
              v-model="sessionDialogForm.sysSchoolClassSessionEndTime"
              style="width: 600px"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '18:30',
                minTime: sessionDialogForm.sysSchoolClassSessionStartTime,
              }"
              placeholder="选择时间"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item
            label="节次描述："
            prop="sysSchoolClassSessionDescription"
            style="margin-left: 11px"
          >
            <el-input
              style="width: 600px"
              v-model="sessionDialogForm.sysSchoolClassSessionDescription"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right; margin-top: 20px">
        <el-button
          @click="
            () => {
              sessionDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleConfirmSessionDicBtn"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addDicInfoApi,
  deleteDicInfoApi,
  getDicInfoDetailApi,
  getDicFormDataListApi,
  updateDicInfoApi,
  getDicFormInfoApi,
  getDicTypeApi,
  getSessionDicInfoApi,
  addSessionDicApi,
  delSessionDicApi,
  getSessionDicDetailApi,
  updateSessionDicApi,
} from "@/api/standard/dictionaryForm";
import { isEmpty, formatDate } from "@/utils/util";
import gradeDicCom from "./gradeDicCom.vue";
import termDicCom from "./termDicCom.vue";
export default {
  name: "dicFormManagement",
  components: {
    gradeDicCom,
    termDicCom,
  },
  data() {
    const checkSysDicGroupCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z_-]/g;
      if (reg.test(this.dicValueDialogForm.sysDicCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      searchInputContent: "",
      dicFormData: [],
      tempDicFormData: [],
      addOrEditValue: 1, // 1表示添加值，0表示编辑值
      addOrEditDicValueDialogVisible: false,
      dicValueDialogForm: {
        sysDicGroupName: "",
        sysDicGroupCode: "",
        sysDicName: "",
        sysDicCode: "",
      },
      rules: {
        sysDicName: [{ required: true, message: "请输入值", trigger: "blur" }],
        sysDicCode: [
          { required: true, message: "请输入code", trigger: "blur" },
          { validator: checkSysDicGroupCode, trigger: "blur" },
        ],
      },
      dicFormValueTableList: [],
      dicTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      sysDicGroupName: "",
      sysDicGroupCode: "",
      sysDicId: "",
      code: "",
      name: "",
      dicTypeName: "",
      sessionData: [],
      sessionCondition: {
        sysSchoolClassSessionName: "",
        sysSchoolClassSessionNum: 0,
        enabled: 0,
      },
      sessionDicTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      addOrEditSession: 1, // 1 添加 0 编辑
      sessionDialogVisible: false,
      sessionDialogForm: {
        sysSchoolClassSessionCode: "",
        sysSchoolClassSessionDescription: "",
        sysSchoolClassSessionEndTime: "",
        sysSchoolClassSessionName: "",
        sysSchoolClassSessionNum: "",
        sysSchoolClassSessionStartTime: "",
      },
      rules: {
        sysSchoolClassSessionName: [
          { required: true, message: "请输入节次名称", trigger: "blur" },
        ],
        sysSchoolClassSessionCode: [
          { required: true, message: "请输入节次码", trigger: "blur" },
        ],
        // sysSchoolClassSessionNum: [
        //   { required: true, message: "请选择节次信息", trigger: "change" },
        // ],
        sysSchoolClassSessionStartTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        sysSchoolClassSessionEndTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      sysSchoolClassSessionId: "",
    };
  },
  watch: {
    searchInputContent(val) {
      if (!val) {
        this.dicFormData = this.tempDicFormData;
        this.dicFormData.forEach((it) => {
          it.isSelected = 0;
        });
        this.dicFormValueTableList = [];
        this.dicTableDataMsgPage.pageTotal = 0;
      } else {
        this.dicFormData = this.tempDicFormData.filter(
          (item) => item.name.indexOf(val) > -1
        );
      }
    },
  },
  created() {
    this.fetchDicTypeData();
    this.fetchSessionDicData();
  },
  methods: {
    // 获取左侧字典类型
    fetchDicTypeData() {
      getDicTypeApi().then((res) => {
        if (res.success) {
          this.dicFormData = res.data.map((item) => {
            return {
              ...item,
              isSelected: 0,
            };
          });
          this.tempDicFormData = [...this.dicFormData];
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取右侧字典值的数据
    fetchDicFormValueData() {
      let params = {
        sysDicType: this.code,
        enabled: 0,
        sysDicGroupCode: "",
        sysDicGroupName: "",
      };
      getDicFormDataListApi(
        this.dicTableDataMsgPage.pageIndex,
        this.dicTableDataMsgPage.pageSize,
        params
      ).then((res) => {
        if (res.success) {
          this.dicFormValueTableList = res.data;
          this.dicTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取节次字典表数据
    fetchSessionDicData() {
      getSessionDicInfoApi(
        this.sessionDicTableDataMsgPage.pageIndex,
        this.sessionDicTableDataMsgPage.pageSize,
        this.sessionCondition
      ).then((res) => {
        if (res.success) {
          this.sessionData = res.data;
          this.sessionDicTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 左侧字典项点击事件
    handleLeftItemClick(item) {
      this.dicFormData.forEach((it) => {
        it.isSelected = 0;
      });
      item.isSelected = 1;
      this.code = item.code;
      this.name = item.name;
      this.dicTypeName = item.dicTypeName;
      this.fetchDicFormValueData();
    },
    // 添加字典表按钮
    handleAddValueBtnClick() {
      if (!this.code) {
        this.$message.warning("请选择字典类型!");
        return;
      }
      this.addOrEditValue = 1;
      this.dicValueDialogForm = {
        sysDicGroupName: this.name,
        sysDicGroupCode: this.dicTypeName,
        sysDicName: "",
        sysDicCode: "",
      };
      this.addOrEditDicValueDialogVisible = true;
    },
    // 编辑字典表按钮
    handleEditDicFormValueBtnClick(row) {
      getDicInfoDetailApi(row.sysDicId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.dicValueDialogForm = {
            sysDicGroupName: data.sysDicGroupName,
            sysDicGroupCode: data.sysDicGroupCode
              ? data.sysDicGroupCode
              : data.sysDicType,
            sysDicName: data.sysDicName,
            sysDicCode: data.sysDicCode,
          };
          this.sysDicId = data.sysDicId;
          this.addOrEditValue = 0;
          this.addOrEditDicValueDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 确认添加或编辑字典表按钮
    handleConfirmAddOrEditDicFormBtnClick() {
      this.$refs.dicValueDialogForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditValue === 1) {
            // 这里进行添加操作
            let addDicInfoParams = {
              sysDicGroupName: this.dicValueDialogForm.sysDicGroupName,
              sysDicGroupCode: this.dicValueDialogForm.sysDicGroupCode,
              sysDicType: this.code,
              sysDicName: this.dicValueDialogForm.sysDicName,
              sysDicCode: this.dicValueDialogForm.sysDicCode,
              enabled: 1,
            };
            addDicInfoApi(addDicInfoParams).then((res) => {
              if (res.success) {
                this.$message.success("添加成功!");
                this.fetchDicFormValueData();
                this.addOrEditDicValueDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 这里进行编辑操作
            let editDicFormValueParams = {
              ...this.dicValueDialogForm,
              sysDicId: this.sysDicId,
              enabled: 1,
            };
            updateDicInfoApi(editDicFormValueParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功!");
                this.fetchDicFormValueData();
                this.addOrEditDicValueDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 删除字典表按钮
    handleDeleteDicFormValueClick(row) {
      this.$confirm("确定要删除该字典数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDicInfoApi([row.sysDicId]).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.$nextTick(() => {
                this.fetchDicFormValueData();
              });
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 分页改变数据
    handleSizeChangeDicTableDataMsgPage(val) {
      this.dicTableDataMsgPage.pageIndex = 1;
      this.dicTableDataMsgPage.pageSize = val;
      this.fetchDicFormValueData();
    },
    handleCurrentChangeDicTableDataMsgPage(val) {
      this.dicTableDataMsgPage.pageIndex = val;
      this.fetchDicFormValueData();
    },
    // 节次字典表分页方法
    handleCurrentChangeSessionDicTableDataMsgPage(val) {
      this.sessionDicTableDataMsgPage.pageIndex = val;
      this.fetchSessionDicData();
    },
    handleSizeChangeSessionDicTableDataMsgPage(val) {
      this.sessionDicTableDataMsgPage.pageIndex = 1;
      this.sessionDicTableDataMsgPage.pageSize = val;
      this.fetchSessionDicData();
    },
    handleResetSessionTable() {
      this.sessionCondition = {
        enabled: 0,
        sysSchoolClassSessionName: "",
        sysSchoolClassSessionNum: 0,
      };
      this.handleQuerySessionTable();
    },
    handleQuerySessionTable() {
      this.sessionDicTableDataMsgPage.pageIndex = 1;
      this.sessionDicTableDataMsgPage.pageSize = 10;
      this.fetchSessionDicData();
    },
    // 添加节次字典表
    handleAddSessionDic() {
      this.sessionDialogForm = {
        sysSchoolClassSessionCode: "",
        sysSchoolClassSessionDescription: "",
        sysSchoolClassSessionEndTime: "",
        sysSchoolClassSessionName: "",
        sysSchoolClassSessionNum: "",
        sysSchoolClassSessionStartTime: "",
      };
      this.addOrEditSession = 1;
      this.sessionDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.sessionDialogForm.clearValidate();
      });
    },
    // 编辑节次字典表
    handleEditSessionDic(row) {
      getSessionDicDetailApi(row.sysSchoolClassSessionId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.sessionDialogForm = {
            sysSchoolClassSessionCode: data.sysSchoolClassSessionCode,
            sysSchoolClassSessionDescription:
              data.sysSchoolClassSessionDescription,
            sysSchoolClassSessionEndTime: formatDate(
              new Date(data.sysSchoolClassSessionEndTime),
              "hh:mm"
            ),
            sysSchoolClassSessionName: data.sysSchoolClassSessionName,
            sysSchoolClassSessionNum: data.sysSchoolClassSessionNum,
            sysSchoolClassSessionStartTime: formatDate(
              new Date(data.sysSchoolClassSessionStartTime),
              "hh:mm"
            ),
          };
          this.addOrEditSession = 0;
          this.sysSchoolClassSessionId = data.sysSchoolClassSessionId;
          this.sessionDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 确认添加或编辑节次字典表
    handleConfirmSessionDicBtn() {
      this.$refs.sessionDialogForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditSession == 1) {
            // 添加
            let addParams = {
              enabled: 2,
              sysSchoolClassSessionCode:
                this.sessionDialogForm.sysSchoolClassSessionCode,
              sysSchoolClassSessionDescription:
                this.sessionDialogForm.sysSchoolClassSessionDescription,
              sysSchoolClassSessionEndTime: this.getTimeStamp(
                this.sessionDialogForm.sysSchoolClassSessionEndTime
              ),
              sysSchoolClassSessionName:
                this.sessionDialogForm.sysSchoolClassSessionName,
              sysSchoolClassSessionNum:
                this.sessionDialogForm.sysSchoolClassSessionNum,
              sysSchoolClassSessionStartTime: this.getTimeStamp(
                this.sessionDialogForm.sysSchoolClassSessionStartTime
              ),
            };
            addSessionDicApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success("添加成功");
                this.fetchSessionDicData();
                this.sessionDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑
            let editParams = {
              enabled: 2,
              sysSchoolClassSessionCode:
                this.sessionDialogForm.sysSchoolClassSessionCode,
              sysSchoolClassSessionDescription:
                this.sessionDialogForm.sysSchoolClassSessionDescription,
              sysSchoolClassSessionEndTime: this.getTimeStamp(
                this.sessionDialogForm.sysSchoolClassSessionEndTime
              ),
              sysSchoolClassSessionName:
                this.sessionDialogForm.sysSchoolClassSessionName,
              sysSchoolClassSessionNum:
                this.sessionDialogForm.sysSchoolClassSessionNum,
              sysSchoolClassSessionStartTime: this.getTimeStamp(
                this.sessionDialogForm.sysSchoolClassSessionStartTime
              ),
              sysSchoolClassSessionId: this.sysSchoolClassSessionId,
            };
            updateSessionDicApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功");
                this.fetchSessionDicData();
                this.sessionDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 改变节次字典表状态
    handleSessionEnabledChange(id, keyWord) {
      this.$confirm(`确定${keyWord}该节次吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (keyWord === "删除") {
            // 进行删除操作
            delSessionDicApi([id]).then((res) => {
              if (res.success) {
                this.fetchSessionDicData();
                this.$message({
                  type: "success",
                  message: `${keyWord}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let params = {
              sysSchoolClassSessionId: id,
              enabled: keyWord === "启用" ? 1 : 2,
            };
            // 进行编辑操作
            updateSessionDicApi(params).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: `${keyWord}成功!`,
                });
                this.fetchSessionDicData();
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${keyWord}`,
          });
        });
    },
    // 调用该方法将 "08:30" 转换当天时间戳
    getTimeStamp(str) {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();
      let day = currentDate.getDate();
      let strArr = str.split(":");
      let hour = Number(strArr[0]);
      let min = Number(strArr[1]);
      let timestamp = new Date(
        `${year}/${month}/${day} ${hour}:${min}`
      ).getTime();
      return timestamp;
    },
    // 格式化
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "sysSchoolClassSessionStartTime":
          return formatDate(
            new Date(row.sysSchoolClassSessionStartTime),
            "hh:mm"
          );
        case "sysSchoolClassSessionEndTime":
          return formatDate(
            new Date(row.sysSchoolClassSessionEndTime),
            "hh:mm"
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dic-form-container {
  display: flex;
  width: 1500px;
  height: 800px;
  padding: 10px;
}
.left-container {
  width: 300px;
  height: 750px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 10px;
}
.right-container {
  width: 900px;
  height: 750px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
}
.title {
  padding-left: 5px;
  border-left: 2px solid #ccc;
  font-size: 18px;
  height: 18px;
  line-height: 18px;
}
.left-content {
  margin-top: 20px;
}
.left-item-container {
  margin-top: 30px;
}
.left-item {
  margin: 0 10px;
  width: 280px;
  height: 40px;
  border-bottom: 1px solid #ccc;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    background-color: rgb(129, 211, 248);
    color: #fff;
  }
  &.selected {
    background-color: rgb(129, 211, 248);
    color: #fff;
  }
}
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .dicValueDialogForm {
  .el-form-item__error {
    margin-left: 100px;
  }
}
</style>
