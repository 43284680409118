<template>
  <div>
    <el-button type="primary" @click="handleAddTermBtn">添加学期</el-button>
    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          label="所属学年"
          prop="sysSchoolTermYear"
        ></el-table-column>
        <el-table-column
          label="学期名称"
          prop="sysSchoolTermName"
        ></el-table-column>
        <el-table-column
          label="学期编码"
          prop="sysSchoolTermCode"
        ></el-table-column>
        <el-table-column
          label="学期描述"
          prop="sysSchoolTermDescription"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="sysSchoolTermStartTime"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          label="结束时间"
          prop="sysSchoolTermEndTime"
          :formatter="customFormatter"
        ></el-table-column>
        <!-- <el-table-column label="状态">
            <template slot-scope="scope">
              {{ scope.row.enabled == 1 ? "启用" : "禁用" }}
            </template>
          </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              style="cursor: pointer; color: #00f; margin-right: 10px"
              @click="handleEditSessionDic(scope.row)"
              >编辑</span
            >
            <span
              style="cursor: pointer; color: #f40"
              @click="
                handleSessionEnabledChange(scope.row.sysSchoolTermId, '删除')
              "
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTableDataMsgPage"
        @current-change="handleCurrentChangeTableDataMsgPage"
        :current-page="tableDataMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataMsgPage.pageTotal"
      ></el-pagination>
    </el-row>

    <el-dialog
      :title="addOrEdit === 1 ? '新增学期' : '编辑学期'"
      :visible.sync="gradeDialogVisible"
      width="600px"
    >
      <div style="width: 500px; margin: 0 auto">
        <el-form
          :model="dicValueDialogForm"
          ref="dicValueDialogForm"
          class="dicValueDialogForm"
          :rules="rules"
        >
          <el-form-item prop="sysSchoolTermYearDicId" label="所属学年:">
            <el-select
              v-model="dicValueDialogForm.sysSchoolTermYearDicId"
              style="width: 400px"
            >
              <el-option
                v-for="item in gradeList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="sysSchoolTermName" label="学期名称:">
            <el-input
              v-model="dicValueDialogForm.sysSchoolTermName"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysSchoolTermCode" label="学期编码:">
            <el-input
              v-model="dicValueDialogForm.sysSchoolTermCode"
              disabled
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysSchoolTermType" label="学期类型:">
            <el-select
              style="width: 400px"
              v-model="dicValueDialogForm.sysSchoolTermType"
              placeholder="请选择"
            >
              <el-option label="第一学期" :value="1"></el-option>
              <el-option label="第二学期" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="sysSchoolTermDescription" label="学期描述:">
            <el-input
              v-model="dicValueDialogForm.sysSchoolTermDescription"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g, '')"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysSchoolTermStartTime" label="开始时间:">
            <el-date-picker
              v-model="dicValueDialogForm.sysSchoolTermStartTime"
              type="date"
              placeholder="选择日期"
              @change="queryStartDateChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="sysSchoolTermEndTime" label="结束时间:">
            <el-date-picker
              v-model="dicValueDialogForm.sysSchoolTermEndTime"
              type="date"
              placeholder="选择日期"
              @change="queryEndDateChange"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item
              prop="sysSchoolClassGradeNum"
              label="年级:"
              style="margin-left: 11px"
            >
              <el-select
                v-model="dicValueDialogForm.sysSchoolClassGradeNum"
                style="width: 400px; margin-left: 29px"
              >
            <el-option label="一年级" :value="1"></el-option>
            <el-option label="二年级" :value="2"></el-option>
            <el-option label="三年级" :value="3"></el-option>
            <el-option label="四年级" :value="4"></el-option>
            <el-option label="一年级" :value="5"></el-option>
            <el-option label="一年级" :value="6"></el-option>
          </el-select>
            </el-form-item> -->
          <el-form-item>
            <div style="text-align: center">
              <el-button
                v-throttle
                @click="
                  () => {
                    gradeDialogVisible = false;
                  }
                "
                >取消</el-button
              >
              <el-button
                v-throttle
                type="primary"
                @click="handleConfirmAddOrEditDicFormBtnClick"
                >确认</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTermDicApi,
  delTermDicApi,
  getTermDicDetailApi,
  getTermDicListApi,
  updateTermDicApi,
  getDicFormInfoApi,
} from "@/api/standard/dictionaryForm";
import { isEmpty, formatDate } from "@/utils/util";
export default {
  data() {
    return {
      tableCondition: {
        enabled: "",
        sysSchoolTermYearDicId: "",
      },
      tableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
      addOrEdit: 1, // 1 新增 0 编辑
      gradeDialogVisible: false,
      dicValueDialogForm: {
        sysSchoolTermYearDicId: "",
        sysSchoolTermCode: "",
        sysSchoolTermDescription: "",
        sysSchoolTermName: "",
        sysSchoolTermStartTime: "",
        sysSchoolTermEndTime: "",
        sysSchoolTermType: "",
      },
      rules: {
        sysSchoolTermYearDicId: [
          { required: true, message: "请选择学年", trigger: "change" },
        ],
        sysSchoolTermType: [
          { required: true, message: "请选择学期类型", trigger: "change" },
        ],
        sysSchoolTermCode: [
          { required: true, message: "请输入学期编码", trigger: "blur" },
        ],
        sysSchoolTermDescription: [
          { required: true, message: "请输入学期描述", trigger: "blur" },
        ],
        sysSchoolTermName: [
          { required: true, message: "请输入学期名称", trigger: "blur" },
        ],
        sysSchoolTermStartTime: [
          { required: true, message: "请选择学期时间", trigger: "change" },
        ],
        sysSchoolTermEndTime: [
          { required: true, message: "请选择学期时间", trigger: "change" },
        ],
      },
      gradeList: [],
    };
  },
  created() {
    this.fetchGradeDic();
    this.fetchPeriodInfo();
  },
  methods: {
    // 获取年级字典表
    fetchGradeDic() {
      getTermDicListApi(
        this.tableDataMsgPage.pageIndex,
        this.tableDataMsgPage.pageSize,
        this.tableCondition
      ).then((res) => {
        if (res.success) {
          this.tableData = res.data;
          this.tableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取科目以及学段信息
    fetchPeriodInfo() {
      getDicFormInfoApi(6).then((res) => {
        if (res.success) {
          this.gradeList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加年级
    handleAddTermBtn() {
      this.dicValueDialogForm = {
        sysSchoolTermYearDicId: "",
        sysSchoolTermCode: Math.random().toString(16).substring(2, 8),
        sysSchoolTermDescription: "",
        sysSchoolTermName: "",
        sysSchoolTermStartTime: "",
        sysSchoolTermEndTime: "",
        sysSchoolTermType: "",
      };
      this.addOrEdit = 1;
      this.gradeDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.dicValueDialogForm.clearValidate()
      })
    },
    // 编辑
    handleEditSessionDic(row) {
      getTermDicDetailApi(row.sysSchoolTermId).then((res) => {
        if (res.success) {
          this.dicValueDialogForm = {
            sysSchoolTermYearDicId: res.data.sysSchoolTermYearDicId,
            sysSchoolTermCode: res.data.sysSchoolTermCode,
            sysSchoolTermDescription: res.data.sysSchoolTermDescription,
            sysSchoolTermName: res.data.sysSchoolTermName,
            sysSchoolTermType: res.data.sysSchoolTermType,
            sysSchoolTermStartTime: res.data.sysSchoolTermStartTime,
            sysSchoolTermEndTime: res.data.sysSchoolTermEndTime,
            // sysSchoolClassGradeNum: "",
          };
          this.addOrEdit = 0;
          this.sysSchoolTermId = row.sysSchoolTermId;
          this.gradeDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },

    // 确认按钮
    handleConfirmAddOrEditDicFormBtnClick() {
      this.$refs.dicValueDialogForm.validate((valid) => {
        if (valid) {
          if (
            this.dicValueDialogForm.sysSchoolTermStartTime >
            this.dicValueDialogForm.sysSchoolTermEndTime
          ) {
            this.$message.error("结束时间不应早于开始时间");
            return
          }
          if (this.addOrEdit == 1) {
            let addParams = {
              ...this.dicValueDialogForm,
              // enabled: 1,
            };
            addTermDicApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success("添加成功");
                this.fetchGradeDic();
                this.gradeDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let editParams = {
              ...this.dicValueDialogForm,
              sysSchoolTermId: this.sysSchoolTermId,
              // enabled: 1,
            };
            updateTermDicApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功");
                this.fetchGradeDic();
                this.gradeDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },

    // 改变节次字典表状态
    handleSessionEnabledChange(id, keyWord) {
      this.$confirm(`确定${keyWord}该学期吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (keyWord === "删除") {
            // 进行删除操作
            delTermDicApi([id]).then((res) => {
              if (res.success) {
                this.fetchGradeDic();
                this.$message({
                  type: "success",
                  message: `${keyWord}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let params = {
              sysSchoolTermId: id,
              enabled: keyWord === "启用" ? 1 : 2,
            };
            // 进行编辑操作
            updateTermDicApi(params).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: `${keyWord}成功!`,
                });
                this.fetchGradeDic();
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${keyWord}`,
          });
        });
    },
    // 分页
    handleCurrentChangeTableDataMsgPage(val) {
      this.tableDataMsgPage.pageIndex = val;
      this.fetchGradeDic();
    },
    handleSizeChangeTableDataMsgPage(val) {
      this.tableDataMsgPage.pageIndex = 1;
      this.tableDataMsgPage.pageSize = val;
      this.fetchGradeDic();
    },

    queryStartDateChange(val) {
      this.dicValueDialogForm.sysSchoolTermStartTime = Date.parse(val);
    },
    queryEndDateChange(val) {
      this.dicValueDialogForm.sysSchoolTermEndTime = Date.parse(val);
    },

    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "sysSchoolTermStartTime":
          if (isEmpty(row.sysSchoolTermStartTime)) {
            return "/";
          }
          return formatDate(new Date(row.sysSchoolTermStartTime), "yyyy/MM/dd");
        case "sysSchoolTermEndTime":
          if (isEmpty(row.sysSchoolTermEndTime)) {
            return "/";
          }
          return formatDate(new Date(row.sysSchoolTermEndTime), "yyyy/MM/dd");
      }
    },
  },
};
</script>

<style></style>
